import { Product1, Product2, Product3 } from 'img/imgconsts';
import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import styled from 'styled-components';

const Card = styled.div`
    background: ${(props) => props.theme.colors.surface};
    border: 1px solid ${(props) => props.theme.colors.border};
    border-radius: 12px;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    gap: 3rem;

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 1rem;
        margin: 0 0.5rem;
        align-items: center;
        gap: 2rem;
    }
`;

const SliderSection = styled.div`
    flex: 1;
    max-width: 550px;
    width: 100%;
    position: relative;

    @media (max-width: 768px) {
        max-width: 100%;
        width: 100%;
    }
`;

const ContentSection = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const SliderWrapper = styled.div`
    position: relative;
    margin: 0 40px;

    .slick-slider {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .slick-list {
        width: 100%;
        overflow: hidden;

        @media (max-width: 1000px) {
            width: 300px;
        }

        @media (max-width: 768px) {
            width: 260px;
        }
    }

    .slick-track {
        display: flex;
        align-items: center;
    }

    .slick-slide {
        width: 100% !important;

        & > div {
            width: 100%;
        }
    }

    .slick-dots {
        position: relative;
        bottom: 0;
        margin-top: 1rem;

        li {
            width: 80px;
            height: 80px;
            margin: 0 5px;

            @media (max-width: 768px) {
                width: 60px;
                height: 60px;
            }

            button {
                width: 80px;
                height: 80px;
                padding: 0;

                @media (max-width: 768px) {
                    width: 60px;
                    height: 60px;
                }

                &:before {
                    display: none;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border: 2px solid transparent;
                border-radius: 4px;
            }

            &.slick-active img {
                border-color: ${(props) => props.theme.colors.border};
            }
        }
    }

    /* Arrow styles */
    .slick-prev,
    .slick-next {
        width: 40px;
        height: 40px;
        z-index: 1;

        &:before {
            font-size: 40px;
            color: ${(props) => props.theme.colors.textPrimary};
            opacity: 0.5;
        }

        &:hover:before {
            opacity: 1;
        }
    }

    .slick-prev {
        left: -40px;
    }

    .slick-next {
        right: -40px;
    }

    @media (max-width: 768px) {
        margin: 0 30px;

        .slick-prev {
            left: -30px;
        }

        .slick-next {
            right: -30px;
        }

        .slick-prev,
        .slick-next {
            width: 25px;
            height: 25px;

            &:before {
                font-size: 25px;
            }
        }
    }
`;

const ProductImage = styled.img`
    width: 100%;
    max-height: 550px;
    height: auto;
    border-radius: 8px;
    object-fit: contain;

    @media (max-width: 768px) {
        max-height: 75vw;
    }
`;

const ThumbnailImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
`;

const ProductInfo = styled.div`
    color: ${(props) => props.theme.colors.textPrimary};
    text-align: left;
`;

const ProductTitle = styled.h2`
    font-size: 2rem;
    margin-bottom: 1rem;
`;

const ProductDescription = styled.p`
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
`;

const OrderInfo = styled.div`
    background: rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 8px;
    margin-top: auto;
`;

const ProductSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        customPaging: function (i: number) {
            const images = [Product1, Product2, Product3];
            return (
                <a>
                    <ThumbnailImage src={images[i]} alt={`Thumbnail ${i + 1}`} />
                </a>
            );
        }
    };

    return (
        <Card>
            <SliderSection>
                <SliderWrapper>
                    <Slider {...settings}>
                        <div>
                            <ProductImage src={Product1} alt="Sweatshirt Design 1" />
                        </div>
                        <div>
                            <ProductImage src={Product2} alt="Sweatshirt Design 2" />
                        </div>
                        <div>
                            <ProductImage src={Product3} alt="Sweatshirt Design 3" />
                        </div>
                    </Slider>
                </SliderWrapper>
            </SliderSection>

            <ContentSection>
                <ProductInfo>
                    <ProductTitle>Limited Edition Sweatshirt</ProductTitle>
                    <ProductDescription>
                        Show your support for the Forging Brains Podcast with this ultra-comfortable sweatshirt.
                        Designed for listeners who appreciate great conversations, deep insights, and a passion for
                        learning, this sweatshirt is perfect for everyday wear. Made from premium, soft-touch fabric, it
                        keeps you cozy whether you're tuning in to the latest episode, out and about, or just relaxing
                        at home.
                    </ProductDescription>

                    <OrderInfo>
                        <h3>How to Order</h3>
                        <p>
                            1. Send payment via PayPal to: <strong>forgingbrainspodcast@gmail.com</strong>
                        </p>
                        <p>2. Include the following in your PayPal note:</p>
                        <ul>
                            <li>Size (S/M/L/XL/XXL)</li>
                            <li>Shipping Address</li>
                            <li>Contact Email</li>
                        </ul>
                        <p>Price: $40.00 (Including Shipping)</p>
                        <p>Each sweatshirt is made to order, so delivery times may vary.</p>
                        <p>
                            Questions? Feel free to <Link to="/contact">contact us</Link>!
                        </p>
                    </OrderInfo>
                </ProductInfo>
            </ContentSection>
        </Card>
    );
};

export default ProductSlider;
