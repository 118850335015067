import { ReactElement } from 'react';
import AppLayout from '../layouts/AppLayout';
import React from 'react';
import ProductSlider from '../components/shop/ProductSlider';
import styled from 'styled-components';

const ShopContainer = styled.div`
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
`;

const Shop = (): ReactElement => {
    return (
        <AppLayout>
            <ShopContainer>
                <ProductSlider />
            </ShopContainer>
        </AppLayout>
    );
};

export default Shop;
